import { Button, Card, CircularProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../shared/hooks';
import { IListagemEmpresa, Empresaservice } from '../../shared/services/api/Empresas/EmpresasService';

interface ILayoutBaseDePaginaProps {
	children: React.ReactNode;
	ferramentasDaListagem?: ReactNode | undefined;
	barraDeFerramentas?: ReactNode | undefined;
}

export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({ children, barraDeFerramentas: barraDeFerramentas, ferramentasDaListagem: ferramentasDaListagem }) => {

	const { debounce } = useDebounce(300, false);
	const [isLoadingEmpresas, setIsLoadingEmpresas] = useState(true);
	const [rowsEmpresas, setRowsEmpresas] = useState<IListagemEmpresa[]>([]);
	useEffect(() => {
		debounce(() => {
			if (isLoadingEmpresas) {
				Empresaservice.getAll()
					.then((result) => {
						setIsLoadingEmpresas(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							setRowsEmpresas(result.data);
						}
					});
			}
		});
	});
	return (
		<Box display='flex' flexDirection='column' width='99vw'>
			<Stack direction={'row'} spacing={50} bgcolor='#085784' height='5vh'>
				<Typography gutterBottom variant='body2'
					display={'flex'}
					position={'absolute'}
					right='45%'>
					<img src="/Logo-arquivoteca-para-footer-min.png" height={28} />
				</Typography>
				<Box flex={1} display='flex' justifyContent='end'>
					<Button
						style={{
							fontSize: '12px',
							color: 'white',
							display: 'flex'
						}}
						disableElevation
						variant='outlined'
						size='small'
						onClick={() => { window.location.href = 'https://www.ecmhorus.com.br'; }}
					>Ir para o Site</Button>
				</Box>
			</Stack>

			<Box padding={1} sx={{ backgroundColor: '#D3D3D3', height: 25 }}>
				<Card variant="outlined" sx={{
					boxShadow: 1,
					borderRadius: 1,
					p: 1,
					minWidth: 150,
					maxWidth: 200,
				}} >
					{isLoadingEmpresas && (
						<CircularProgress />
					)}
					{!isLoadingEmpresas && rowsEmpresas.map(rowEmpresa => {
						return (
							<Typography key={0} fontSize={10} align='left' color='#085784' fontWeight='bold'>
								Empresa: {rowEmpresa.empresa_nome}
							</Typography>
						);
					})}
				</Card>
			</Box>

			{
				ferramentasDaListagem && (
					<Typography>
						<Box>
							{ferramentasDaListagem}
						</Box>
					</Typography>
				)
			}

			{
				barraDeFerramentas && (
					<Typography>
						<Box>
							{barraDeFerramentas}
						</Box>
					</Typography>
				)
			}

			<Typography>
				<Box>
					{children}
				</Box>
			</Typography>
		</Box >
	);
};