import { Api } from '../axios-config';
import { Enviroment } from '../../../enviroments';

interface IAuth {
	accessToken: string;
}

export interface IAccess {
	nome_aplicacao: string;
	cod_aplicacao: number;
}

type TAccess = {
	data: IAccess[];
	totalCount: number;
}

export interface ICheckLogin {
	loginstatus: string;
}

type TCheckLogin = {
	data: ICheckLogin[];
}

const auth = async (email: string, password: string): Promise<IAuth | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);

		// Colocar criptografia depois!
		const url = `/auth/index.php?accessToken=${accessToken}&email=${email}&password=${password}`;
		const { data } = await Api.get(url);

		if (data) {
			if (data.accessToken != '') {
				return data;
			} else {
				return new Error('Email ou Senha inválidos!');
			}
		} else {
			return new Error('Erro de token!');
		}
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const getAccess = async (aplicacao = ''): Promise<TAccess | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);

		const urlRelativa = '/auth/getAccess.php?accessToken=' + accessToken + '&aplicacao=' + aplicacao;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data,
				totalCount: Number(headers['x-total-count'] || Enviroment.LIMITE_DE_LINHAS),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const checklogin = async (): Promise<TCheckLogin | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);

		// Colocar criptografia depois!
		const url = `/auth/checklogin.php?accessToken=${accessToken}`;
		const { data } = await Api.get(url);

		if (data) {
			return { data };
		}
		return new Error('Erro de Login!');
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || 'Erro ao verificar login!!');
	}
};

export const AuthService = {
	auth,
	getAccess,
	checklogin
};