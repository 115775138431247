import { ObjectToQuery } from '../../../../utils';
import { Enviroment } from '../../../enviroments';
import { Api } from '../axios-config';

export interface IDetalheArquivosAvulsosDetalhado {
	id_documento_campos: number;
	id_tipologia: number;
	id_processo: number;
	field01string: string;
	field02string: string;
	field03string: string;
	field04string: string;
	field05string: string;
	field06string: string;
	field07string: string;
	field08string: string;
	field09string: string;
	field10string: string;
	field11string: string;
	field12string: string;
	field13string: string;
	field14string: string;
	field15string: string;
	field16string: string;
	field17string: string;
	field18string: string;
	field19string: string;
	field20string: string;
	field21string: string;
	field22string: string;
	field23string: string;
	field24string: string;
	field25string: string;
	field26string: string;
	field27string: string;
	field28string: string;
	field29string: string;
	field30string: string;
	field31string: string;
	field32string: string;
	field33string: string;
	field34string: string;
	field35string: string;
	field36string: string;
	field37string: string;
	field38string: string;
	field39string: string;
	field40string: string;
	field41string: string;
	field42string: string;
	field43string: string;
	field44string: string;
	field45string: string;
	field46string: string;
	field47string: string;
	field48string: string;
	field49string: string;
	field50string: string;
	field51string: string;
	field52string: string;
	field53string: string;
	field54string: string;
	field55string: string;
	field56string: string;
	field57string: string;
	field58string: string;
	field59string: string;
	field60string: string;
	field61double: number;
	field62double: number;
	field63double: number;
	field64double: number;
	field65double: number;
	field66double: number;
	field67double: number;
	field68double: number;
	field69double: number;
	field70double: number;
	field71date: string;
	field72date: string;
	field73date: string;
	field74date: string;
	field75date: string;
	field76date: string;
	field77date: string;
	field78date: string;
	field79date: string;
	field80date: string;
}

type TArquivosComTotalCount = {
	data: IDetalheArquivosAvulsosDetalhado[];
	totalCount: number;
}

type TArquivosSommenteTotalCount = {
	totalCount: number;
}

const getAll = async (pagina = 1, arquivo_nome_like = '', busca_id_tipologia = '', id_processo = ''): Promise<TArquivosComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const params = ObjectToQuery({ pagina, arquivo_nome_like, busca_id_tipologia, id_processo });
		const urlRelativa = `/DocumentoCampos/ObterArquivosAvulsosDetalhados?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || Enviroment.LIMITE_DE_LINHAS),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getTotal = async (arquivo_nome_like = '', busca_id_tipologia = '', id_processo = ''): Promise<TArquivosSommenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const params = ObjectToQuery({ arquivo_nome_like, busca_id_tipologia });
		const urlRelativa = `/DocumentoCampos/ObterQtdeTotal?${params}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

export const ArquivosAvulsosDetalhadoService = {
	getAll,
	getTotal
};