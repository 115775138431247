import { ObjectToQuery } from '../../../../utils';
import { Enviroment } from '../../../enviroments';
import { Api } from '../axios-config';

export interface IListagemArquivo {
	id_documento_eletronico: number;
	id_tipologia: number;
	arquivo_nome: string;
	arquivo_dados: string;
}

export interface IDetalheArquivo {
	id_documento_eletronico: number;
	id_tipologia: number;
	arquivo_nome: string;
	arquivo_dados: string;
}

type TArquivosComTotalCount = {
	data: IListagemArquivo[];
	totalCount: number;
}

type TArquivosSomenteTotalCount = {
	totalCount: number;
}

const getTotal = async (arquivo_nome_like = '', busca_id_tipologia = ''): Promise<TArquivosSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const params = ObjectToQuery({ arquivo_nome_like, busca_id_tipologia });
		const urlRelativa = `/DocumentoEletronico/ObterQtdeTotal?${params}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (pagina = 1, arquivo_nome_like = '', busca_id_tipologia = ''): Promise<TArquivosComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const params = ObjectToQuery({ pagina, arquivo_nome_like, busca_id_tipologia });
		const urlRelativa = `/DocumentoEletronico/ObterTodos?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || Enviroment.LIMITE_DE_LINHAS),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<IDetalheArquivo | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const { data } = await Api.get(`/DocumentoEletronico/ObterPorId?id_documento_eletronico=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const create = async (dados: Omit<IDetalheArquivo, 'id'>): Promise<number | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const { data } = await Api.post<IDetalheArquivo>('/Arquivos/post.php', dados);

		if (data) {
			return data.id_documento_eletronico;
		}
		return new Error('Erro ao criar o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
	}
};

const updateById = async (id: number, dados: IDetalheArquivo): Promise<void | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		await Api.put(`/arquivos/update.php?accessToken=${accessToken}&id=${id}`, dados);

	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao alterar o registro.');
	}
};

const deleteById = async (id: number): Promise<void | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		await Api.delete(`/arquivos/delete.php?accessToken=${accessToken}&id=${id}`);

	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao deletar o registro.');
	}
};

export const Arquivoservice = {
	getTotal,
	getAll,
	getById,
	create,
	updateById,
	deleteById
};
