import { Pagination, Paper, TableFooter, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, Box, Stack, CircularProgress, Grid, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from '../../shared/components';
import { Enviroment } from '../../shared/enviroments';
import { useDebounce } from '../../shared/hooks';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { IListagemArquivo, Arquivoservice } from '../../shared/services/api/Arquivos/ArquivosService';

export const ListagemDeArquivos: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [totalCount, setTotalCount] = useState(0);
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [rowsFiles, setRowsFiles] = useState<IListagemArquivo[]>([]);
	const { debounce } = useDebounce(50, false);
	const [filtrarVermelho, setFiltrarVermelho] = useState(false);

	const busca = useMemo(() => {
		return searchParams.get('busca') || '';
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get('pagina') || '1');
	}, [searchParams]);

	useEffect(() => {
		debounce(() => {
			setIsLoading(true);
			Arquivoservice.getAll(pagina, busca)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setRowsFiles(result.data);
						busca.length > 0 ? setFiltrarVermelho(true) : null;
					}
				});
			Arquivoservice.getTotal(busca)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setTotalCount(result.totalCount);
					}
				});
		});
	}, [busca, pagina]);

	let keynum = 0;

	return (
		<LayoutBaseDePagina>
			{(
				<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
					<TableContainer component={Paper} variant='outlined' sx={{ border: 0 }}>
						<Table sx={{ border: 0 }}>
							<TableHead>
								<TableRow>
									<TableCell colSpan={2} sx={{ border: 0 }}>
										<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
											<FerramentasDaListagem
												mostrarInputBusca={true}
												mostrarBotaoVoltar={false}
												mostrarBotaoNovo={false}
												mostrarBotaoNovoModal={true}
												textoBotaoNovo='Novo'
												textoDaBusca={searchParams.get('busca') ?? ''}
												aoClicarEmNovo={() => { /* handleOpen();*/ }}
												aoMudarTextoDeBusca={texto => { setSearchParams({ busca: texto, pagina: '1' }, { replace: true }); setFiltrarVermelho(false); }}
											/>
										</Box>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{ border: 0 }}>
								{isLoading ? <CircularProgress /> : null}
								{!isLoading ? rowsFiles.map(rowFiles => {
									keynum++;
									const arrArquivo_nome = rowFiles.arquivo_nome.split(new RegExp(busca, 'i'));
									const arrArquivo_dados = rowFiles.arquivo_dados.split(new RegExp(busca, 'i'));
									return (
										<TableRow key={keynum}>
											<TableCell sx={{ border: 0 }}>
												<Table>
													<TableRow>
														<TableCell>{busca.length >= 1 ? arrArquivo_nome.map((t, i) => {
															return (
																<Box component="span" key=''>
																	{t.substring(t.length - 300)}<Box
																		component="span"
																		sx={{
																			p: 0,
																			color: filtrarVermelho ? 'red' : '',
																			textDecoration: filtrarVermelho ? 'underline' : '',
																			fontWeight: filtrarVermelho ? 'bold' : ''
																		}}>{i < arrArquivo_nome.length - 1 && busca}</Box>
																</Box>
															);
														}) : null}{busca.length <= 0 ? rowFiles.arquivo_nome : null}</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>{busca.length >= 1 ? arrArquivo_dados.map((t, i) => {
															return (
																<Box component="span" key=''>
																	{t.substring(t.length - 300)}<Box
																		component="span"
																		sx={{
																			p: 0,
																			color: filtrarVermelho ? 'red' : '',
																			textDecoration: filtrarVermelho ? 'underline' : '',
																			fontWeight: filtrarVermelho ? 'bold' : ''
																		}}>{i < arrArquivo_dados.length - 1 && busca}</Box>
																</Box>
															);
														}) : null}{busca.length <= 0 ? rowFiles.arquivo_dados.substring(0, 300) : null}</TableCell>
													</TableRow>
												</Table>
											</TableCell>
										</TableRow>
									);
								}) : null}
							</TableBody>
							<TableFooter>
								{totalCount === 0 && !isLoading ? (
									<TableRow>
										<TableCell colSpan={2} sx={{ border: 0 }}>
											{ /* Enviroment.LISTAGEM_VAZIA */}
										</TableCell>
									</TableRow>
								) : null}
								<TableRow>
									<TableCell colSpan={2} sx={{ border: 0 }}>
										<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
											<Grid item width={250} height={100} border={1} borderRadius={2} bgcolor='#e7e7e7'>
												<Box padding={2} display='flex' justifyContent='center' alignItems='center' height={60} >
													<Typography variant='h5' align='center' color='#085784' fontWeight='bold'
														onClick={() => navigate('/listararquivosprocessos')}>
														Meus processos
													</Typography>
												</Box>
											</Grid>
										</Box>
									</TableCell>
								</TableRow>
								{totalCount > 0 && totalCount > Enviroment.LIMITE_DE_LINHAS ? (
									<TableRow>
										<TableCell colSpan={2} sx={{ border: 0 }}>
											<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
												<Pagination
													page={pagina}
													count={Math.ceil(totalCount / Enviroment.LIMITE_DE_LINHAS)}
													onChange={(_, newPage) => setSearchParams({ busca: busca, pagina: newPage.toString() }, { replace: true })}
												/>
											</Box>
										</TableCell>
									</TableRow>
								) : null}
								<TableRow>
									<TableCell colSpan={2} height={200} sx={{ border: 0 }}>
									</TableCell>
								</TableRow>
								<TableRow sx={{ backgroundColor: '#D3D3D3', borderColor: '#D3D3D3' }}>
									<TableCell colSpan={2} sx={{ borderBottom: 0 }}>
										<Box display='flex' justifyContent='right' alignItems='center'
											sx={{ borderTop: 1, borderTopColor: '#999999' }}>
											<Typography align='center' fontWeight='bold'>
												Usuário: nononono
											</Typography>
										</Box>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Box>
			)
			}
		</LayoutBaseDePagina >
	);
};