import { ObjectToQuery } from '../../../../utils';
import { Enviroment } from '../../../enviroments';
import { Api } from '../axios-config';

export interface IDetalheArquivosAvulsos {
	id_tipologia: number;
	id_documento_eletronico: number;
	arquivo_nome: string;
	arquivo_dados: string;
}

type TArquivosComTotalCount = {
	data: IDetalheArquivosAvulsos[];
	totalCount: number;
}

type TArquivosSommenteTotalCount = {
	totalCount: number;
}

const create = async (dados: Omit<IDetalheArquivosAvulsos, 'id'>): Promise<number | Error> => {
	try {
		const { data } = await Api.post<IDetalheArquivosAvulsos>('/ArquivosAvulsos/upload.php', dados);

		if (data) {
			return data.id_tipologia;
		}
		return new Error('Erro ao criar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
	}
};

const getAll = async (pagina = 1, arquivo_nome_like = '', busca_id_tipologia = ''): Promise<TArquivosComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const params = ObjectToQuery({ pagina, arquivo_nome_like, busca_id_tipologia });
		const urlRelativa = `/DocumentoEletronico/ObterTodos?${params}`;
		// const urlRelativa = `/ArquivosAvulsos/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${Enviroment.LIMITE_DE_LINHAS}&Arquivo_nome_like=${filter}&busca_id_tipologia=${busca_id_tipologia}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || Enviroment.LIMITE_DE_LINHAS),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getTotal = async (arquivo_nome_like = '', busca_id_tipologia = ''): Promise<TArquivosSommenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = Enviroment.LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN) || '';

		const params = ObjectToQuery({ arquivo_nome_like, busca_id_tipologia });
		const urlRelativa = `/DocumentoEletronico/ObterQtdeTotal?${params}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

export const ArquivosAvulsosService = {
	create,
	getAll,
	getTotal
};