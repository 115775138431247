import { Routes, Route, Navigate } from 'react-router-dom';

import { DetalheDeArquivos, ListagemDeArquivos, ListagemDeArquivosAvulsos, ListagemDeArquivosProcessos, ListagemDeArquivosAvulsosDetalhado } from '../pages';

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path="pesquisar" element={<ListagemDeArquivos />} />
			<Route path="incluir/detalhe" element={<DetalheDeArquivos />} />
			<Route path="listararquivosavulsos" element={<ListagemDeArquivosAvulsos />} />
			<Route path="listararquivosprocessos" element={<ListagemDeArquivosProcessos />} />
			<Route path="listararquivosavulsosDetalhado/:id" element={<ListagemDeArquivosAvulsosDetalhado />} />

			<Route path="*" element={<Navigate to="pesquisar" />} />

		</Routes>
	);
};
